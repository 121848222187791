import * as client_hooks from "/src/hooks.client.js";


export { matchers } from "/.svelte-kit/generated/client/matchers.js?t=1744262710268";

export const nodes = [
	() => import("/.svelte-kit/generated/client/nodes/0.js?t=1744263662169"),
	() => import("/.svelte-kit/generated/client/nodes/1.js?t=1744262710250"),
	() => import("/.svelte-kit/generated/client/nodes/2.js?t=1744263662169"),
	() => import("/.svelte-kit/generated/client/nodes/3.js?t=1744263662169"),
	() => import("/.svelte-kit/generated/client/nodes/4.js?t=1744262710254"),
	() => import("/.svelte-kit/generated/client/nodes/5.js?t=1744262710254"),
	() => import("/.svelte-kit/generated/client/nodes/6.js?t=1744262710254"),
	() => import("/.svelte-kit/generated/client/nodes/7.js?t=1744262710257"),
	() => import("/.svelte-kit/generated/client/nodes/8.js?t=1744262710258"),
	() => import("/.svelte-kit/generated/client/nodes/9.js?t=1744262710262"),
	() => import("/.svelte-kit/generated/client/nodes/10.js?t=1744262710265"),
	() => import("/.svelte-kit/generated/client/nodes/11.js?t=1744263662694"),
	() => import("/.svelte-kit/generated/client/nodes/12.js?t=1744262710266"),
	() => import("/.svelte-kit/generated/client/nodes/13.js?t=1744262710266"),
	() => import("/.svelte-kit/generated/client/nodes/14.js?t=1744262710267")
];

export const server_loads = [];

export const dictionary = {
		"/": [~2],
		"/admin": [~4],
		"/admin/ai-prompt": [~6],
		"/admin/components": [7],
		"/admin/pagebuilder/editor/[id]": [~8],
		"/admin/pages/edit/[id]": [9],
		"/admin/[id]": [~5],
		"/components": [10],
		"/components/edit/[id]": [~11],
		"/components/preview/[id]": [12],
		"/posts": [~13],
		"/posts/[id]": [~14],
		"/[slug]": [~3]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from "/.svelte-kit/generated/root.js?t=1744262710276";